var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-primary rounded-xl w-full p-4"},[_c('div',{staticClass:"mx-4 bg-white-15 px-4 py-1 flex items-center justify-between"},[_c('p',{staticClass:"text-3xl leading-none uppercase"},[_vm._v("TOTAL DISTANCE: "+_vm._s(Number(_vm.total).toLocaleString('nb-NO'))+" km")]),_c('p',{staticClass:"text-3xl leading-none uppercase"},[_vm._v("Remaining Distance: "+_vm._s(Math.max(Number(_vm.total - _vm.doneDistance), 0).toLocaleString('nb-NO'))+" km")])]),_c('div',{staticClass:"DT mx-24 block mt-16 relative text-left"},[_c('table',{staticClass:"bg-white-fade w-full relative font-medium text-3xl"},[_c('thead',{staticClass:"bg-white-30"},[_c('tr',{staticClass:"border-2 border-white gold-gradient",style:({'background-size': (_vm.percent + "%")})},_vm._l((_vm.formattedCheckpoints),function(c,i){return _c('th',{key:i,staticClass:"h-12",style:({width: ((c.percentToNext) + "%")})})}),0)]),_c('tbody',[_c('tr',_vm._l((_vm.formattedCheckpoints),function(c,i){return _c('td',{key:i,class:c.percentDone <= _vm.percent ? 'text-white' : 'text-white-30'},[_vm._v(_vm._s(c.name))])}),0)]),_c('svg',{staticClass:"absolute top-0",style:({
            'margin-top': '-42px',
            'margin-left': '-2px',
            height: '5.5rem',
            left: (_vm.percent + "%")
            }),attrs:{"viewBox":"0 0 28 88","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 0L0 88H5.04489L5.04489 26.3288L28 14L0 0Z","fill":"#C94B3A"}})]),_c('div',{staticClass:"absolute top-0 px-10",style:({
            'margin-left': '-2px',
            height: '5.5rem',
            left: ((_vm.percent - (_vm.percent > 75 ? 18 : 0)) + "%")
            })},[_c('p',{staticClass:"text-3xl font-bold italic -mt-12 whitespace-no-wrap"},[_vm._v(_vm._s(_vm.doneDistance)+" KM")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }