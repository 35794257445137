var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:{
    'grid gap-4' : _vm.grid,
    'md:grid-cols-1 lg:grid-cols-2': _vm.grid && _vm.columns == 2,
    'md:grid-cols-2 lg:grid-cols-3': _vm.grid && _vm.columns == 3,
    'md:grid-cols-2 lg:grid-cols-4': _vm.grid && _vm.columns == 4,
    'md:grid-cols-3 lg:grid-cols-5': _vm.grid && _vm.columns == 5,
    'md:grid-cols-3 lg:grid-cols-6': _vm.grid && _vm.columns == 6,
    'md:grid-cols-4 lg:grid-cols-7': _vm.grid && _vm.columns == 7,
    'md:grid-cols-4 lg:grid-cols-8': _vm.grid && _vm.columns == 8,
    'md:grid-cols-5 lg:grid-cols-9': _vm.grid && _vm.columns == 9,
    'md:grid-cols-5 lg:grid-cols-10': _vm.grid && _vm.columns == 10,
    }},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }